<template>
  <div class="container">

    <ErrorPopup
      :error="error_popup_message"
    />


    <MemberDeactivateModal
      ref="deactivateModal"
      :deactivated="local_member.deactivated"
      :deactivated_at="local_member.deactivated_at"
      :member_id="local_member.member_id"
      @deactivatedUpdated="memberDeactivatedUpdated"
    />

    <b-form-group
      v-if="local_member.reg"
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="8">
      <b-button @click="force_reg" variant="danger" >{{ $t('MEMBER.UNSET_REG') }}</b-button>
      <p class="mt-4" style="color: #ffb85d; font-style: italic;">{{ $t('MEMBER.REG_INFO') }}</p>
    </b-form-group>


    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4">
      <div class="d-flex">
        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input type="checkbox" name="" v-model="local_member.secret" @change="change_data" />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer">{{$t('MEMBER.SECRET')}}</span>
      </div>
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4">
      <div class="d-flex">
        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input type="checkbox" name="" v-model="local_member.do_autorenew" @change="change_data" />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer">{{$t('MEMBER.AUTORENEW')}}</span>
      </div>
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4">
        <div class="d-flex">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="local_member.deceased" @change="change_data" />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('MEMBER.MEMBER_DECEASED')}}</span>
        </div>
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4">
      <div class="d-flex">
        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input type="checkbox" name="" v-model="local_member.deactivated" :disabled="true" @change="change_data" />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer">{{$t('MEMBER.MEMBER_DEACTIVATED')}}</span>
      </div>
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4"
      :label="$t('MEMBER.DEACTIVATE_AT')"
    >
      <memlist-date-picker
        v-model="local_member.deactivate_at"
        :disabled="true"
      ></memlist-date-picker>
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4"
      >
      <b-button v-if="!creating && local_member.deactivated" variant="primary" @click.prevent="open_deactivate_modal">{{$t('MEMBER.ACTIVATE')}}</b-button>
      <b-button v-if="!creating && !local_member.deactivated" variant="danger" @click.prevent="open_deactivate_modal">{{$t('MEMBER.DEACTIVATE')}}</b-button>
    </b-form-group>

    
    <b-form-group
      v-if="settings.is_faith"
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="8">

      <div class="d-sm-flex justify-content-begin align-items-right align-bottom w-100">
        <b-button :disabled="disabled_deactivate_letter" @click="print_member_exit_application" variant="danger" >{{ $t('MEMBER.PRINT_DEACTIVATE_LETTER') }}</b-button>
        <b-form-select
        style="max-width: 200px; margin-left: 16px;"
        :disabled="disabled_deactivate_letter"
        v-model="lang"
        :options="[
          { value: 'sv', text: $t('LANGUAGE.SWEDISH') },
          { value: 'ar', text: $t('LANGUAGE.ARABIC') },
          { value: 'bs', text: $t('LANGUAGE.BOSNIAN') }
        ]"
      >
      </b-form-select>
      </div>
      
      
      <p class="mt-4" style="color: #ffb85d; font-style: italic;">{{ $t('MEMBER.PRINT_DEACTIVATE_LETTER_INFO') }}</p>
    </b-form-group>


    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4"
      :label="$t('MEMBER.STATUS')">
      <b-form-select
        id="member-status"
        v-model="local_member.status"
        :disabled="true"
        :options="member_status_options"
        />
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4"
      :label="$t('MEMBER.TYPE')">
      <b-form-select
        id="member-membertype"
        v-model="local_member.type"
        :disabled="local_member.deceased || local_member.deactivated"
        :options="member_types_options"
        @change="change_data"
      />
    </b-form-group>

    <b-form-group
      label-cols-md="4"
      label-cols-sm="10"
      content-cols-sm="10"
      content-cols-md="4"
      :label="$t('MEMBER.FIRST_REGISTER')">
      <b-form-input
        autocomplete="off"
        v-model="local_member.rdt"
        @change="change_data"
      />
    </b-form-group>

    <RightSaveButton
      ref="save-button"
      :text="
        !this.creating
          ? $t('COMMON.SAVE')
          : $t('COMMON.CREATE')
      "
      @clicked="submit_clicked()"
    />
  </div>

</template>

<style lang="css" scoped>
input:disabled + span {
  background-color: #dfdfdf !important;
}

.top-row {
  margin-bottom: 16px;
}

.icon-container {
  width: 50px;
}

.container {
  content: "";
  display: block;
  padding-bottom: 48px;
}
</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import MemberDeactivateModal from '@/view/components/member_editor_v2/MemberDeactivateModal.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';

export default {

  name: 'MemberEditorStatus',

  props: ['member'],

  mixins: [ toasts ],
  emits: ['change', 'create', 'update'],
  components: {
    MemberDeactivateModal,
    RightSaveButton,
    ErrorPopup
  },

  watch: {
    member: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_member = { ...this.member };
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS']),
    creating() {
      return !(this.local_member && this.local_member.member_id);
    },
    disabled_deactivate_letter() {
      return !(this.local_member.deactivate_at && dayjs(this.local_member.deactivate_at).isValid());
    }
  },

  methods: {


    async print_member_exit_application() {
      try {
        const res = await axios.post(`/samfund/deactivated_member/${this.local_member.member_id}`, { lang: this.lang });

        if (res.status === 409) {
          if (res.data.reason.indexOf('skv_period') >= 0) {
            this.error_popup_message = 'ERR_MEMBER_MISSING_SKV_PERIOD';

            this.$nextTick(()=>{ this.error_popup_message = null; });
            return;
          }
        }

        if (res.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_DOWNLOAD'));
          return;
        }

        downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

      } catch (error) {
        console.error(error);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_DOWNLOAD'));
      }
    },

    force_reg() {
      this.local_member.reg = false;
      this.$emit('update', this.local_member);
    },

    change_data() {
      this.$emit('change', this.local_member);
    },

    memberDeactivatedUpdated(member_id, deactivated, deactivate_at) {
      this.local_member.deactivated = deactivated;
      this.local_member.deactivate_at = deactivate_at;

      this.$emit('change', this.local_member);
    },

    open_deactivate_modal() {
      this.$refs['deactivateModal'].show();
    },

    submit_clicked() {
      if (this.creating) {
        this.$emit('create', this.local_member);
      }
      else {
        this.$emit('update', this.local_member);
      }

      this.$refs['save-button'].stop();
    },

    async load_member_types() {
      try {

        const res = await axios.get('/membertype');

        if (res.status === 200) {
          this.member_types_options = [];

          for (const mt of res.data) {
            this.member_types_options.push({ value: mt.id, text: mt.name });
          }
        }
      }
      catch (err) {
        console.error('error loading membertypes', err);
      }
    }


  },

  mounted() {
    this.load_member_types();

    if (this.member) {
      this.local_member = { ...this.member };
    }
  },

  data() {
    return {

      lang: 'sv',

      error_popup_message: null,

      local_member: {},

      member_types_options: [],

      member_status_options: [
        { text: this.$t('MEMBER.STATUSES.MEMBER'), value: 'MEMBER' },
        { text: this.$t('MEMBER.STATUSES.PENDING'), value: 'PENDING' },
        { text: this.$t('MEMBER.STATUSES.REJECTED'), value: 'REJECTED' }
      ],

    };
  }
};

</script>
